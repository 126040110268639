
import { DataTableHeader } from "vuetify"
const ApiError = () => import("@/common/ApiError.vue").then(m => m.default || m)
const RemovePersonDialog = () => import("@/components/dialogs/RemovePersonDialog.vue")
const ReassignPersonDialog = () => import("@/components/dialogs/ReassignPersonDialog.vue")

import { Person, Client, ClientPersons, SnackbarInfo, AclExtract } from "@/types/responses"
import { CustomerEnum, ClientEnum } from "@/types/enums"

import Vue from "vue"
export default Vue.extend({
	name: "PersonsList",
	components: {
		ApiError,
		RemovePersonDialog,
		ReassignPersonDialog
	},
	mounted() {
		this.getUsers()
	},
	props: {
        clientPersons: {
			type: Boolean,
            required: true
		}
    },
	data(): {
		customerenm: typeof CustomerEnum
		clientenm: typeof ClientEnum

		loadError: boolean
		error: string | null | undefined

		search: string
		remove: boolean
		reassign: boolean
		selectedItem: null | Person
		loading: boolean
		persons: Person[]
		headers: DataTableHeader[]
		timeLeft: number
		
		snackBar: boolean
		snackBarText: string

        isLastMember: boolean
	} {
		return {
			customerenm: CustomerEnum,
			clientenm: ClientEnum,
			
			loadError: false,
			error: null,

			search: "",
			remove: false,
			reassign: false,
			selectedItem: null,
			loading: false,
			persons: [],
			headers: [
				{ text: "Name", value: "displayName", sortable: false },
				{ text: "User Id", value: "id", sortable: false },
				{ text: "Action", value: "action", sortable: false, filterable: false },
			],
			timeLeft: 2,

			snackBar: false,
			snackBarText: "",

            isLastMember: false
		}
	},
    watch:{
        users: {
            deep: true,
            handler(){
                console.log("users");
            }
        },
        personsComplete: {
            deep: true,
            handler(){
                console.log("personsComplete");
            }
        },
        clientPersonsComplete: {
            deep: true,
            handler(){
                console.log("clientPersonsComplete");
            }
        }
    },
	computed: {
		getAcl(): AclExtract {
			if(this.$route.name?.includes(this.customerenm.Customer)){
				return this.$store.state.acl[this.customerenm.Customer]
			}
			return this.$store.state.acl[this.clientenm.ClientOrganization]
		},
		enm(): typeof CustomerEnum | typeof ClientEnum {
			if(this.$route.name?.includes(this.customerenm.Customer)){
				return this.customerenm
			}

			return this.clientenm
		},
		listPersonAcl(): boolean {
			if(
				!this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ListPersons)
			} else if (
				this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ClientListPersons)
			}

			return this.getAcl.ui.includes(this.clientenm.ClientListPersons)
		},
		removePersonAcl(): boolean {
			if(
				!this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.RemovePersons)
			} else if (
				this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ClientRemovePersons)
			}

			return this.getAcl.ui.includes(this.clientenm.ClientRemovePersons)
		},
		reassignPersonToCompanyAcl(): boolean {
			if(
				!this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ReassignPersonsToCompany)
			} else if (
				this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ClientReassignPersonsToCompany)
			}

			return this.getAcl.ui.includes(this.clientenm.ClientReassignPersonsToCompany)
		},
		reassignPersonToExistingAcl(): boolean {
			if(
				!this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ReassignPersonsToExisting)
			} else if (
				this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ClientReassignPersonsToExisting)
			} 

			return this.getAcl.ui.includes(this.clientenm.ClientReassignPersonsToExisting)
		},
		reassignPersonToNewAcl(): boolean {
			if(
				!this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ReassignPersonsToNew)
			} else if (
				this.clientPersons &&
				this.$route.name?.includes(this.customerenm.Customer)){
				return this.getAcl.ui.includes(this.customerenm.ClientReassignPersonsToNew)
			}

			return this.getAcl.ui.includes(this.clientenm.ClientReassignPersonsToNew)
		},
        users(): Person[] {
            if(this.clientPersons) return this.clientPersonsComplete
            else return this.personsComplete
        },
		personsComplete(): Person[] {
			return this.$store.state.context.persons
		},
        clientPersonsComplete(): Person[] {
			if(this.selectedClient !== undefined) {
                const hit = this.$store.state.context.clientPersons.find((client: ClientPersons) => {
                    return client.clientId === this.selectedClient!.id
                })
                
                if(hit) return hit.persons;
            }
            return []
		},
        selectedClient(): null | Client {
			return this.$store.state.context.selectedClient;
		},
	},
	methods: {
		showRemoveDialog(item: Person): void {
			if(item){
				this.$vStore.dispatch("context/setPerson", item)
				this.selectedItem = item
				this.remove = true
                console.log("PERSONS: ", this.users.length)
                // If this is the last 
                if (this.users.length === 1){
                    console.log("IS LAST MEMBER!")
                    this.isLastMember = true
                }
			}
		},
		showReassignDialog(item: Person): void {
			this.selectedItem = item
			this.reassign = true
            if (this.users.length === 1){
                (console.log("selected user is last member."))
                this.isLastMember = true
            }
		},
        removeLastMember(): void{
            console.log("Closing dialog, client company will be removed.")
            this.remove = false,
			this.reassign = false,
            this.$emit('closeDialog', true)
        },
		async countDownTimer(snackbarText: string): Promise<void> {
			this.timeLeft = 2
			this.snackBarText = snackbarText + `<br/><br/>Finalizing in ${this.timeLeft} seconds.. `
			return new Promise((resolve) => {
				const downloadTimer = setInterval(() => {
					this.timeLeft--
					this.snackBarText = snackbarText + `<br/><br/>Finalizing in ${this.timeLeft} seconds.. `
					if (this.timeLeft <= 0) {
						clearInterval(downloadTimer)
						resolve()
					}
				}, 1000)
			})
		},

        async refreshUsers(): Promise<void> {
            if(this.clientPersons) await this.refreshClientPersons()
            else await this.refreshPersons()
        },
		async refreshPersons(): Promise<void> {
			this.loading = true
			try {
				await this.$vStore.dispatch("context/updatePersons")
				await this.countDownTimer("")
			} catch (e) {
				console.warn("Could not find any persons for this customer", e)
				this.determineError(e)
			}
			this.loading = false
		},
        async refreshClientPersons(): Promise<void> {
			this.loading = true
			try {
				await this.$vStore.dispatch("context/updateClientPersons")
                await this.countDownTimer("")
			} catch (e) {
				console.warn("updateClientPersons failed.", e)
				this.determineError(e)
			}
			this.loading = false
		},

        async silentRefreshUsers(): Promise<void> {
            await this.silentRefresh()
        },
		async silentRefresh(): Promise<void> {
			try {
				this.$vStore.dispatch("context/updatePersons")
				this.$vStore.dispatch("context/updateCustomerClients")
				this.$vStore.dispatch("context/updateClientPersons")
			} catch (e) {
				console.warn("silentRefresh failed.", e)
			}
		},

        async getUsers(): Promise<void> {
            if(this.clientPersons) await this.getClientPersons()
            else await this.getPersons()
        },
		async getPersons(): Promise<void> {
			if (this.loading) return
			if (this.$store.state.context.persons.length > 0) return
			this.loading = true
			try {
				await this.$vStore.dispatch("context/updatePersons")
			} catch (e) {
				console.warn("Could not find any persons for this customer", e)
				this.determineError(e)
			}
			this.loading = false
		},
        async getClientPersons(): Promise<void> {
			if (this.loading) return
			if (!this.$store.state.context.selectedClient) return
			if (this.users.length > 0) return

			this.loading = true
			try {
				await this.$store.dispatch("context/updateClientPersons")
			} catch (e) {
				console.warn("Could not find any persons for this client", e)
				this.determineError(e)
			}
			this.loading = false
		},

		async showSnackbarInfo(snackbarInfo: SnackbarInfo): Promise<void> {
			this.snackBar = true
			if(snackbarInfo.success){
				this.loading = true
				try{
					await this.countDownTimer(snackbarInfo.msg)
				} catch (e){}
				this.loading = false
			} else {
				this.snackBarText = snackbarInfo.msg + "<br/><br/>Error: " + JSON.stringify(snackbarInfo.e)
			}
		},

		determineError(e: unknown){
			if(e && e instanceof Error) this.error = e.message;
			else  this.error = "Error: Unknown error"

			//if(this.error.includes("403")) this.showForbiddenError();
			this.loadError = true;
		},
	},
})
